//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-YF5guFcQ2bWZBnLqY-Okt0nytbNjD4A",
  authDomain: "lion-mega-a90f9.firebaseapp.com",
  projectId: "lion-mega-a90f9",
  storageBucket: "lion-mega-a90f9.appspot.com",
  messagingSenderId: "126632751415",
  appId: "1:126632751415:web:cfd41e11dae770fd102ab2"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
